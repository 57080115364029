import React from "react";
import styled from "styled-components";
import { Card, Typography, Button } from "@mui/material";
import ReactPlayer from "react-player";
import CategoryTitle from "./CategoryTitle";

const StyledCard = styled(Card)`
  background-color: #25314;
  padding: 20px;
  text-align: center;
`;

const PlayerWrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const SoundCloudIcon = styled.iframe`
  width: 32px;
  height: 32px;
  border: none;
`;

export const SoundCloudSection = ({ name, soundcloudUrl, fallbackInfo }) => {
  const isPlayable = ReactPlayer.canPlay(soundcloudUrl);

  return (
    <>
      <CategoryTitle>{name}</CategoryTitle>
      <StyledCard>
        {isPlayable ? (
          <PlayerWrapper>
            <div>
              <iframe
                width="100%"
                height="300"
                scrolling="no"
                frameBorder="no" // Corrected attribute to camelCase
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/855132647%3Fsecret_token%3Ds-WlCfv&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
              <div
                style={{
                  fontSize: "10px",
                  color: "#cccccc",
                  lineBreak: "anywhere",
                  wordBreak: "normal",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontFamily:
                    "Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif",
                  fontWeight: 100,
                }}
              >
                <a
                  href="https://soundcloud.com/yoavasif"
                  title="Yoav Asif | Composer"
                  target="_blank"
                  rel="noopener noreferrer" // Added rel for security
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  Yoav Asif | Composer
                </a>{" "}
                ·{" "}
                <a
                  href="https://soundcloud.com/yoavasif/sets/winning-shot-composed-by-dor-rozen-yoav-asif/s-WlCfv"
                  title="Winning Shot EP - Trailerized Rock (Composed By Dor Rozen & Yoav Asif)"
                  target="_blank"
                  rel="noopener noreferrer" // Added rel for security
                  style={{ color: "#cccccc", textDecoration: "none" }}
                >
                  Winning Shot EP - Trailerized Rock (Composed By Dor Rozen &
                  Yoav Asif)
                </a>
              </div>
            </div>
          </PlayerWrapper>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              {fallbackInfo.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {fallbackInfo.artist}
            </Typography>
            <Typography variant="body1" paragraph>
              {fallbackInfo.description}
            </Typography>
            <StyledButton
              variant="contained"
              color="primary"
              href={soundcloudUrl}
              target="_blank"
              rel="noopener noreferrer" // Added rel for security
            >
              View on SoundCloud
            </StyledButton>
            <Typography
              variant="body2"
              style={{ marginTop: "10px", color: "gray" }}
            >
              Embedding may be restricted due to privacy settings.
            </Typography>

            {/* SoundCloud Icon */}
            <SoundCloudIcon
              allowTransparency="true"
              scrolling="no"
              frameBorder="no" // Corrected attribute to camelCase
              src="https://w.soundcloud.com/icon/?url=http%3A%2F%2Fsoundcloud.com%2Fyoavasif%2Fsets%2Fwinning-shot-composed-by-dor-rozen-yoav-asif%2Fs-WlCfv&color=orange_white&size=32"
            />
          </>
        )}
      </StyledCard>
    </>
  );
};
